// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
    production: false,
    hmr: false,
    appConfig: 'appconfig.json',
    analytics:{
      baseUrl: 'https://opex-uat-analytics-api.azurewebsites.net/',
      applicationId: '00000000-0000-0000-0000-000000000001',
      apiKey: '67987338c1b04ec8814e3ccbe1735c8e-48ff62e3c5a34f47880db6c0772543ec'
    },
    raygunKey: 'ilEMKiq6xt7lzAKNRTQ'
};
